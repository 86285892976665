import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.replace";
import axios from 'axios';
import Cookie from 'js-cookie';
var MOA_AUTH_INFO = process.env.VUE_APP_AUTH_INFO || 'MOA_AUTH_INFO';
var MOA_AUTH_PROJECT = process.env.VUE_APP_AUTH_PROJECT;
var MOA_AUTH_URL = process.env.VUE_APP_AUTH_URL || 'https://login.moa.moonton.net';
var MOA_CALLBACK_URL = process.env.BASE_URL.replace(/\/+$/, '') + '/#/moa/login';

// const info = JSON.parse(localStorage.getItem(MOA_AUTH_INFO) || '{}')
var info = JSON.parse(Cookie.get(MOA_AUTH_INFO) || '{}');
var state = {
  token: info.token || '',
  uid: info.uid || '',
  nick: info.nick || '',
  name: info.name || '',
  avatar: info.avatar || '',
  perms: []
};
var mutations = {
  UPDATE_USER: function UPDATE_USER(state, info) {
    if (info) {
      state.token = info.token || '';
      state.uid = info.uid || '';
      state.nick = info.nick || '';
      state.name = info.name || '';
      state.avatar = info.avatar || "".concat(MOA_AUTH_URL, "/avatar/").concat(info.nick, "-64.jpg");
    }
    Cookie.set(MOA_AUTH_INFO, JSON.stringify(info || {}), {
      expires: 7,
      domain: process.env.VUE_APP_DOMAIN
    });

    // localStorage.setItem(MOA_AUTH_INFO, JSON.stringify(info || {}))
  },

  UPDATE_PERMS: function UPDATE_PERMS(state, perms) {
    if (perms) {
      state.perms = perms;
    }
  }
};
var actions = {
  login: function login(context) {
    var route = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var redirect = window.location.origin + MOA_CALLBACK_URL;
    if (route && route !== '/') {
      redirect += '?route=' + encodeURIComponent(route);
    }
    redirect = encodeURIComponent(redirect);
    window.location.href = "".concat(MOA_AUTH_URL, "/login?project=").concat(MOA_AUTH_PROJECT, "&redirect=").concat(redirect);
  },
  update: function update(_ref, info) {
    var commit = _ref.commit;
    commit('UPDATE_USER', info);
  },
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    commit('UPDATE_USER', {});
    var token = encodeURIComponent(state.token);
    var redirect = encodeURIComponent(window.location.origin + MOA_CALLBACK_URL);
    window.location.href = "".concat(MOA_AUTH_URL, "/logout?token=").concat(token, "&project=").concat(MOA_AUTH_PROJECT, "&redirect=").concat(redirect);
  },
  refresh_token: function refresh_token(_ref3) {
    var dispatch = _ref3.dispatch,
      commit = _ref3.commit,
      state = _ref3.state;
    if (!state.token) {
      return;
    }
    return axios({
      url: MOA_AUTH_URL + '/api/refresh',
      method: 'post',
      data: {
        token: state.token
      }
    }).then(function (result) {
      if (result.data.code === 0) {
        commit('UPDATE_USER', result.data.result);
      } else {
        dispatch('logout');
      }
    });
  },
  get_perms: function get_perms(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    if (!state.token) {
      return;
    }
    return axios({
      url: MOA_AUTH_URL + '/api/getperms',
      method: 'post',
      data: {
        token: state.token
      }
    }).then(function (result) {
      if (result.data.code === 0) {
        commit('UPDATE_PERMS', result.data.result);
      }
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};