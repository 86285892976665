import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/web.dom.iterable";
import path from 'path';
import { isExternal } from "../../utils/validate";
var state = {
  routes: [],
  tree: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.routes = routes;
    var isShow = function isShow(item) {
      return item.meta && item.meta.title;
    };
    var resolvePath = function resolvePath(base, routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(base)) {
        return base;
      }
      return path.resolve(base, routePath);
    };
    var collect = function collect(items) {
      var base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var nodes = [];
      items.forEach(function (item) {
        var routePath = resolvePath(base, item.path);
        var subs;
        if (item.children) {
          subs = collect(item.children, routePath);
        }
        if (isShow(item)) {
          var node = {
            path: routePath,
            meta: item.meta,
            hidden: item.hidden
          };
          if (subs && subs.length > 0) {
            node.children = subs;
          }
          nodes.push(node);
        } else if (subs && subs.length > 0) {
          nodes.push.apply(nodes, _toConsumableArray(subs));
        }
      });
      return nodes;
    };
    state.tree = collect(routes);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};